<template>
	<main>
		<div
			v-if="fetchingCarousels"
			class="d-flex justify-content-center align-items-center"
			>
			<div class="spinner-border" role="status"></div>
		</div>
		<div v-else>
			<b-card no-body>
				<b-table
					caption-top
					show-empty
					striped
					selectable
					hover
					responsive
					:items="carousels"
					:fields="fields"
					:current-page="currentPage"
					:busy="fetchingCarousels"
					head-variant="null"
					@row-clicked="viewCarousel"
					>
					<template #table-caption>
						<div class="px-3 d-flex justify-content-between align-items-center">
							<p style="font-size: 16px; font-weight: bold">Carousel list</p>
							<p
								v-if="carousels?.length"
								style="color: red; cursor: pointer"
								@click="handleCarouselDelete"
								>
								<span
									style="color: red; font-size: 20px; cursor: pointer"
									class="fa fa-trash-o"
									></span>
								Clear all
							</p>
						</div>
					</template>
					<template #table-busy>
						<div
							class="d-flex flex-column justify-content-center align-items-center"
							>
							<div class="spinner-border" role="status"></div>
							<p class="text-center mt-2"><strong>Loading...</strong></p>
						</div>
					</template>

					<template #empty>
						<p class="text-center text-secondary">No records available</p>
					</template>

					<template #cell(date)="data">
						{{ data.item.date }}
					</template>
					<template #cell(title)="data">
						{{ data.item.title }}
					</template>
					<template #cell(multiple_images)="data">
						{{ data.item.multiple_images }}
					</template>
					<template #cell(enable)="data">
						<label class="switch">
							<input
								v-model="data.item.status"
								@change="toggleStory(data.item)"
								type="checkbox"
								/>
							<span class="slider round"></span>
						</label>
					</template>
				</b-table>
			</b-card>

			<div class="card-footer" v-if="totalRecords">
				<b-pagination
					v-model="currentPage"
					:total-rows="totalRecords"
					:per-page="perPage"
					></b-pagination>
			</div>
		</div>
	</main>
</template>

<script>
import Swal from 'sweetalert2'
import moment from 'moment'
export default {
  name: 'CarouselConfig',
  data () {
    return {
      fields: [
        {
          key: 'date',
          label: 'Date'
        },
        {
          key: 'title',
          label: 'Carousel Title'
        },
        {
          key: 'multiple_images',
          label: 'Multple images'
        },
        {
          key: 'enable',
          label: 'Enable'
        }
      ],
      perPage: 20,
      currentPage: 1,
      totalRecords: 0,
      fetchingCarousels: false,
      deletingCarousel: false,
      carousels: []
    }
  },
  created () {
    this.loadCarousels()
  },
  methods: {
    viewCarousel (carousel) {
      this.$router.push({
        name: 'ShowCarousel',
        params: { carouselId: carousel.id }
      })
    },
    async handleCarouselDelete () {
      const deleteStatus = this.carousels.every((eachCarousel) => {
        return eachCarousel.status === false
      })

      if (!deleteStatus) {
        Swal.fire(
          'OOPS!',
          'Sorry you cannot delete all carousels while some are still active. Ensure you deactivate all carousels before deleting all.',
          'info'
        )
        return
      }

      this.deletingCarousel = true
      await this.$swal({
        icon: 'question',
        title: 'Please Confirm',
        text: 'Are you sure you want to delete all carousels?',
        showConfirmButton: true,
        showCancelButton: true,
        preConfirm: () => {
          return this.axios
            .delete('/v1/dynamic-dashboard/carousels')
            .then(() => {
              this.deletingCarousel = false
            })
            .catch((error) => {
              this.deletingCarousel = false
              const msg =
                error.response && error.response.data
                  ? error.response.data.message
                  : 'An error occurred, please try again.'

              this.$swal().showValidationMessage(msg)
            })
        },
        allowOutsideClick: () => !Swal.isLoading()
      }).then((result) => {
        if (result.isConfirmed) {
          this.loadCarousels()
          this.$swal({
            icon: 'success',
            title: 'Success',
            text: 'Carousels have been successfully deleted',
            showCloseButton: true
          })
        }
      })
    },
    loadCarousels () {
      this.fetchingCarousels = true
      this.axios
        .get('/v1/dynamic-dashboard/carousels')
        .then((res) => {
          this.carousels = this.transformedCarouselArray(res?.data)
        })
        .catch(() => {
          this.$toastr.e(
            'Something went wrong while fetching carousels',
            'Error'
          )
        })
        .finally(() => {
          this.fetchingCarousels = false
        })
    },
    transformedCarouselArray (payload) {
      const newCarouselData = []
      payload.forEach((eachCarouselObject) => {
        newCarouselData.push({
          id: eachCarouselObject.id,
          title: eachCarouselObject.name,
          date: moment(eachCarouselObject.created_at).format('YYYY-MM-DD'),
          status: eachCarouselObject.status === 'active',
          multiple_images: eachCarouselObject.assets.length > 1 ? 'YES' : 'NO'
        })
      })
      return newCarouselData
    },
    toggleStory (item) {
      if (item.status === true) {
        this.$swal({
          title: 'Enable carousel',
          html: `<p class="pb-0 mb-0" style="font-weight: lighter">Are you sure you would like to make ${item.title} carousel visible to all users ?</p>`,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Proceed!'
        }).then((result) => {
          if (result.isConfirmed) {
            this.handleSubscription(item, result.isConfirmed)
              .then(() => {
                if (item.status === true) {
                  this.$toastr.s(
                    'Carousel visibility has been turned on',
                    'Success'
                  )
                }

                if (item.status === false) {
                  this.$toastr.s(
                    'Carousel visibility has been turned off',
                    'Success'
                  )
                }
              })
              .catch((error) => {
                if (error.message === 'Carousel Turn On Error') {
                  item.status = false
                }

                if (error.message === 'Carousel Turn Off Error') {
                  item.status = true
                }
              })
          } else {
            item.status = false
          }
        })
      }

      if (item.status === false) {
        this.$swal({
          title: 'Disable carousel',
          html: `<p class="pb-0 mb-0" style="font-weight: lighter">Are you sure you would like to make this carousel</p>
                 <p class="pt-0 mt-0" style="font-weight: lighter">not visible to all users ?</p>`,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Proceed!'
        }).then((result) => {
          if (result.isConfirmed) {
            result.isConfirmed = false
            this.handleSubscription(item, result.isConfirmed)
              .then(() => {
                if (item.status === false) {
                  this.$toastr.s(
                    'Carousel visibility has been turned off',
                    'Success'
                  )
                }
              })
              .catch((error) => {
                if (error.message === 'Carousel Turn On Error') {
                  item.status = false
                }

                if (error.message === 'Carousel Turn Off Error') {
                  item.status = true
                }
              })
          } else {
            item.status = 'active'
          }
        })
      }
    },
    async handleSubscription (item, value) {
      try {
        await this.axios.patch(`/v1/dynamic-dashboard/carousels/${item.id}`, {
          status: value === true ? 'active' : 'inactive'
        })

        if (value) {
          this.$swal(
            'Success!',
            `You have successfully turned on ${item.title} carousel visibility`,
            'success'
          )
          item.status = true
        } else {
          this.$swal(
            'Success!',
            `You have successfully turned off ${item.title} carousel visibility`,
            'success'
          )
          item.status = false
        }
      } catch (error) {
        if (value) {
          this.$toastr.e(error.message, 'Error')
          throw new Error('Carousel Turn On Error')
        } else {
          this.$toastr.e(error.message, 'Error')
          throw new Error('Carousel Turn Off Error')
        }
      }
    }
  }
}
</script>

<style scoped>
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: '';
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #2196f3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
</style>
